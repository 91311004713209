import { gql } from "graphql-request";

import type { RoutesType } from "routing";
import type { Instrument } from "components/CustomSelect";
import { client, makeGQLQuery } from "./makeGQLQuery";
import type {
  Activity,
  AssetType,
  PlatformAccount,
  RealEstate,
  Wealth,
} from "@types";
import { FRAGMENT_ASSETS, FRAGMENT_ASSETS_WITH_TRANSACTIONS } from "./fragment";

// Assets

export const loanUrl = makeGQLQuery({
  chunks: `
    query {
      loans {
        id,
        name,
        type,
        start,
        duration,
        interests,
        assuranceFee,
        amountLocked,
        loanedAmount,
        monthlyPayment,
        applicationFee,
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export type RealestateResponse = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  realEstates: any;
};

export const realEstatesUrl = (variables: {
  start: Date | null;
  end: Date | null;
}) =>
  makeGQLQuery<RealestateResponse>({
    chunks: `
    query RealEstate($start: DateTime, $end: DateTime) {
      realEstates {
        id,
        name,
        area,
        rooms,
        source,
        typeId,
        bedrooms,
        creation,
        condition,
        buyingDate
        orientation,
        asset {
          id
          status
          activities {
            id
            value
            start
            end
          }
          currentActivity {
            value
          }
          performance(start: $start, end: $end) {
            evolution
            gain {
              value
            }
          }
        }
      }
    }`,
    variables: variables,
  });
export const singleRealEstatesUrl = (variables: { id: string }) =>
  makeGQLQuery({
    chunks: `
    query singleRealEstatesUrl($id: ID!) {
      realEstate(id: $id) {
        id,
        name,
        area,
        rooms,
        price,
        typeId,
        source,
        bedrooms,
        creation,
        condition,
        ownership,
        buyingDate
        orientation,
        ${FRAGMENT_ASSETS}
      }
    }`,
    variables,
  });

export const singleSCPIUrl = (variables: { id: string }) =>
  makeGQLQuery({
    chunks: `
    query singleSCPIUrl($id: ID!) {
      privateCivilRealestate(id: $id) {
        id,
        name,
        unitPrice,
        quantity,
        type,
        creation,
        purschaseDate,
        scpi {
          name
          type
          state
          dividend
          gestion_fees
          capitalization
          withdrawal_price
          subscription_fees
          subscription_price
        }
        ${FRAGMENT_ASSETS}
      }
    }`,
    variables,
  });

export const privateCivilRealEstateUrl = makeGQLQuery({
  chunks: `
    query {
      privateCivilRealestates {
        id,
        type,
        name,
        quantity,
        creation,
        unitPrice,
        purschaseDate,
        ${FRAGMENT_ASSETS}
        scpi {
          name
          type
          state
          dividend
          gestion_fees
          capitalization
          withdrawal_price
          subscription_fees
          subscription_price
        }
      }
    }`,
});

export const retirementUrl = makeGQLQuery({
  chunks: `
    query {
      retirements {
        id,
        name,
        type,
        bankName,
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const crowdfundingUrl = makeGQLQuery({
  chunks: `
    query {
      crowdfundings {
        id,
        name,
        endDate,
        efficiency,
        providerName,
        subscribeDate,
        investmentField,
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const lifeInsurancesUrl = makeGQLQuery({
  chunks: `
    query {
      lifeInsurances {
        id,
        name,
        creation,
        efficiency,
        transfersAmount,
        insuranceCompany,
        asset {
          investments {
            id
            code
            label
            portfolioShare
            unitPrice
            unitValue
            quantity
            valuation
          }
        }
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const exoticsUrl = makeGQLQuery({
  chunks: `
    query {
      exotics {
        id,
        name,
        quantity
        creation,
        category,
        unitPrice
        ownership,
        purchaseDate,
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const retirementsUrl = makeGQLQuery({
  chunks: `{
    retirements {
      id,
      name,
      type,
      bankName,
      ${FRAGMENT_ASSETS}
    }
  }`,
});

export const savingsUrl = makeGQLQuery({
  chunks: `
    query {
      savings {
        id
        type
        name
        bank: bankName
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const economiesUrl = makeGQLQuery({
  chunks: `
    query {
      economies { 
        id
        type
        name
        bank: bankName
        ${FRAGMENT_ASSETS}
      }
    }`,
});

export const bankAccountUrl = makeGQLQuery({
  chunks: `
    query {
      bankAccounts {
        id,
        name,
        bank,
        ${FRAGMENT_ASSETS_WITH_TRANSACTIONS}
      }
    }`,
});
export type SCPIListResponse = {
  SCPIList: {
    name: string;
    subscription_price: string;
  }[];
};

// Others
export const SCPIListUrl = makeGQLQuery<SCPIListResponse>({
  chunks: `
    query {
      SCPIList {
        name,
        subscription_price
      }
    }`,
});

export const budgetInsightUrl = makeGQLQuery({
  chunks: "{ budgetInsightWebview }",
});

export const assetsUrl = (type: AssetType) =>
  makeGQLQuery({
    chunks: `{
      assets(type:${type}) {
        id,
        name,
        currentActivity {
          value
        }
      } 
    }`,
  });

export const assetsPeriodUrl = ({
  type,
  start,
  end,
}: {
  type: AssetType | null;
  start: Date | null;
  end: Date | null;
}) =>
  makeGQLQuery({
    chunks: `query assetsPeriodUrl($type: AssetType, $start: DateTime, $end: DateTime){
        assets(type: $type) {
          id,
          name,
          type,
          currentActivity {
            value
          },
          performance (start: $start, end: $end) {
            ${type ? "evolution" : ""}
            gain {
              value,
            }
          }
        }
      }`,
    variables: {
      type,
      start: start ? start.toISOString() : null,
      end: end ? end.toISOString() : null,
    },
  });

interface InstrumentQueryParams {
  contains: string;
  category: RoutesType;
  limit?: number;
}

export type InstrumentsResponse = {
  instruments: Instrument[];
};
export const instrumentUrl = (variables: InstrumentQueryParams) =>
  makeGQLQuery<InstrumentsResponse>({
    chunks: `
      query Instruments($contains: String!, $category: InstrumentCategory!, $limit: Int) {
        instruments(contains: $contains, category: $category, limit: $limit) {
          name
          code
          category
          internationalCode
        }
      }
    `,
    variables,
  });

interface totalWealtProps {
  type: AssetType | null;
  start: Date | null;
  end: Date | null;
  exluding: AssetType[] | null;
}
export const excludingEvolutionUrl = (variables: totalWealtProps) =>
  makeGQLQuery({
    chunks: `
      query ExcludingEvolution ($type: AssetType, $start: DateTime, $end: DateTime, $exluding: [AssetType!]) { 
        totalPerformance(start: $start, end: $end, type: $type, exluding: $exluding) {
          evolution
          gain {
            value
          }
        }
      }
    `,
    variables,
  });

export type Wealths = {
  totalWealth: {
    net: number;
    brut: number;
  };
};
export const totalWealthUrl = ({ exluding, ...variables }: totalWealtProps) =>
  makeGQLQuery<Wealths>({
    chunks: `
      query Wealths ($type: AssetType, $start: DateTime, $end: DateTime) { 
        totalWealth(type: $type) {
          net
          brut
        }
        totalPerformance(start: $start, end: $end, type: $type) {
          evolution
          gain {
            value
          }
        }
        ${
          exluding
            ? `homeEvolution: totalPerformance(start: $start, end: $end, type: $type, exluding: ${exluding}) {
          evolution
          gain {
            value
          }
        }`
            : ""
        }
      }
    `,
    variables,
  });

export const summaryUrl = makeGQLQuery({
  // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
  chunks: `{ summary { name, activities { start, value } } }`,
});

export const wealthUrl = (
  variables: Omit<totalWealtProps, "type" | "exluding">
) =>
  makeGQLQuery<{
    wealth: Wealth[];
  }>({
    chunks: `
      query Wealth( $start: DateTime, $end: DateTime) { 
        wealth {
          type,
          activity {
            start,
            value,
          }
          performance(start: $start, end: $end) {
            evolution
            gain {
              value
            }
          }
        }
      }`,
    variables,
  });

export const summariesUrl = (type: AssetType) =>
  makeGQLQuery({
    chunks: `{ summaries(type:${type}) { name, activities { start, value } } }`,
  });

export const totalWealthRetool = (type: AssetType | null) =>
  makeGQLQuery({
    chunks: `{totalWealth(type: ${type}) {
      net
      brut
    }}`,
  });

export const periodActivitiesUrl = ({
  type,
  start,
  end,
}: {
  type?: AssetType;
  start: Date | null;
  end: Date | null;
}) =>
  makeGQLQuery({
    chunks: `
    query getPeriodActivities($start: DateTime, $end: DateTime, $type: AssetType) {
      periodActivities( start: $start end: $end, type: $type ) {
        value
         start
         end
        }
      }`,
    variables: {
      type,
      start: start ? start.toISOString() : null,
      end: end ? end.toISOString() : null,
    },
  });

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const cryptoPlatformAccountsUrl = ({ type }: { type: AssetType }): any =>
  makeGQLQuery({
    chunks: `
    query PlatformAccounts($type: AssetType!){
      platformAccounts(type: $type) {
        id
        name
        type
        exchange
        ${FRAGMENT_ASSETS}
        cryptos {
          id
          name
          quantity
          creation
          unitPrice
          unitValue
          instrument {
            logo
          }
        }
        stocks {
          id
        }
      }
    }`,
    variables: {
      type,
    },
  });

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const stockPlatformAccountsUrl = ({ type }: { type: AssetType }): any =>
  makeGQLQuery({
    chunks: `
    query PlatformAccounts($type: AssetType!){
      platformAccounts(type: $type) {
        id
        name
        type
        exchange
        ${FRAGMENT_ASSETS}
        stocks {
          id
          name
          code
          quantity
          unitPrice
          valuation
          unitValue
          instrument {
            logo
          }
        }
        cryptos {
          id
        }
      }
    }`,
    variables: {
      type,
    },
  });
type ManualPlatformAccountsResponse = {
  manualPlatformAccounts: PlatformAccount[];
};
export const manualPlatformAccountsUrl = ({ type }: { type: AssetType }) =>
  makeGQLQuery<ManualPlatformAccountsResponse>({
    chunks: `
    query ManualPlatformAccounts($type: AssetType!){
      manualPlatformAccounts(type: $type) {
        id
        name
        type
        exchange
        ${FRAGMENT_ASSETS}
        stocks {
          id
          code
          name
          quantity
          unitPrice
          valuation
          unitValue
          instrument {
            logo
          }
        }
        cryptos {
          id
          name
          quantity
          creation
          instrument {
            logo
          }
        }
      }
    }`,
    variables: {
      type,
    },
  });

interface geoSearchQueryParams {
  text: string;
}
export const geoSearchUrl = (variables: geoSearchQueryParams) => {
  return makeGQLQuery({
    chunks: `
    query GeoSearch($text: String!){
      geoSearch(text: $text) {
        city
        zipcode
        country
        latitude
        longitude
        streetName
        countryCode
        streetNumber
      }
    }
    `,
    variables,
  });
};

export const getUserSettings = makeGQLQuery({
  chunks: `
    query {
      getUserSettings {
        id
        email
        spending
        annualIncome
        savingsPerYear
        monthlyRepayment
        availableLiquidity
        retirementSimulation

        loanDetails {
          debtRate
          loanCapacity
        }
        investorProfile {
          risk
          experience	
        }
        patrimonialsGoals {
          yield
          investmentType
          investmentHorizon
          investmentCategories
        }
        retirementForm {
          id
          userId
          profession
          best25Year
          dateOfBirth
          endOfActivity
          startOfActivity
          yearsOfInterruption
        }
      }
    }`,
});

export const feeAggregation = makeGQLQuery({
  chunks: `
    query {
      feeAggregation {
        key,
        name,
        value,
        instrument,
        reprensentation,
      }
    }`,
});

export type HeaderRealestateResponse = {
  realEstate: {
    asset: {
      currentActivity: {
        value: number;
      };
      performance: {
        evolution: number;
        gain: {
          value: number;
        };
      };
    };
  };
};

export async function headerRealestate(
  id: RealEstate["id"],
  start: Date | null,
  end: Date | null
): Promise<HeaderRealestateResponse> {
  const query = gql`
    query HeaderRealEstate($id: ID!, $start: DateTime, $end: DateTime) {
      realEstate(id: $id) {
        asset {
          currentActivity {
            value
          }
          performance(start: $start, end: $end) {
            evolution
            gain {
              value
            }
          }
        }
      }
    }
  `;

  return await client.request<HeaderRealestateResponse>(query, {
    id: id,
    start: start,
    end: end,
  });
}

export type RealEstateValuationResponse = {
  realEstate: {
    id: string;
    asset: {
      valuationInTime: Activity[];
    };
  };
};

export async function realestateValuation(
  id: RealEstate["id"],
  start: Date | null,
  end: Date | null
): Promise<RealEstateValuationResponse> {
  const query = gql`
    query RealEstateValutation($id: ID!, $start: DateTime, $end: DateTime) {
      realEstate(id: $id) {
        asset {
          valuationInTime(start: $start, end: $end) {
            start
            value
            instrument
            end
          }
        }
      }
    }
  `;

  const response = await client.request<RealEstateValuationResponse>(query, {
    id: id,
    start: start,
    end: end,
  });

  return response;
}

export function getProCode() {
  const query = gql`
    query {
      getProCode
    }
  `;

  return client.request<{ getProCode: string }>(query);
}

export function getCompany() {
  const query = gql`
    query {
      getCompany
    }
  `;

  return client.request<{ getCompany: string }>(query);
}

// Home query
interface HomeResponse {
  wealth: Wealth[];
  periodActivities: Activity[];
}
export function home(
  args:
    | {
        start: null;
        end: null;
        type?: AssetType;
      }
    | {
        start: Date;
        end: Date;
        type?: AssetType;
      }
): Promise<HomeResponse> {
  const query = gql`
    query Home($start: DateTime, $end: DateTime, $type: AssetType) {
      wealth {
        type
        activity {
          start
          value
        }
        performance(start: $start, end: $end) {
          evolution
          gain {
            value
          }
        }
      }

      periodActivities(start: $start, end: $end, type: $type) {
        value
        start
        end
      }
    }
  `;

  return client.request(query, args);
}
