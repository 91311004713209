import { numberToFix } from "core";
import type { InvestorProfileFormInputs } from "./newInvestorProfile.schema";

export function calcInvestorProfileStats(): number[] {
  const results: number[] = [];
  for (let i = 1; i <= 6; i++) results.push(0);

  return results;
}

function calculateSustainableInvestmentScore(
  sustainableInvestment: InvestorProfileFormInputs["sustainableInvestment"]
): number {
  let score = 0;

  if (!sustainableInvestment) return score;

  // 1. Include ESG Dimension (0-1 point)
  if (
    sustainableInvestment.includeEnvironmentalSocialGovernanceDimension !==
    "YES"
  )
    return score;

  score += 1;

  // 2. Definition of Sustainability Component (0-1 point)
  if (
    sustainableInvestment.optionsDefineSustainabilityComponent ===
    "DEFINE_IT_PRECISELY_ANSWERING_4_QUESTIONS"
  ) {
    score += 1;
  } else if (
    sustainableInvestment.optionsDefineSustainabilityComponent ===
    "AGREE_FOLLOW_ESG_POLICY"
  ) {
    score += 0.5;
  }

  // 5. Preferred ESG Dimension (0-0.5 point)
  switch (sustainableInvestment.preferredASGDimension) {
    case "NO_PREFERENCE":
      score += 0.1;
      break;
    case "ENVIRONMENTAL":
    case "SOCIAL":
    case "GOOD_GOVERNANCE":
      score += 0.5;
      break;
    default:
      break;
  }

  switch (sustainableInvestment.taxonomyAlignment) {
    case "0_25":
      score += 0.25;
      break;
    case "26_50":
      score += 0.5;
      break;
    case "51_75":
      score += 0.75;
      break;
    case "76_100":
      score += 1;
      break;
    default:
      break;
  }

  // 6. Exclude Negative Activities (0-0.5 point)
  if (
    sustainableInvestment.excludeNegativeActivitiesEnvironmentalSocial === "YES"
  ) {
    // score += 0.5;

    // 7. Issues to Minimize Negative Impacts (Bonus: 0-0.5 point)
    if (sustainableInvestment.issuesMinimizeNegativeImpacts) {
      const selectedIssues = Object.values(
        sustainableInvestment.issuesMinimizeNegativeImpacts
      ).filter(Boolean);

      // loop limit 5
      for (let i = 0; i < 5; i++) {
        if (selectedIssues[i]) {
          score += 0.1;
        }
      }
    }
  }

  // Cap the score at a maximum of 5 points
  // only take one decimal if the score is not an integer

  return numberToFix(Math.min(score, 5), 2);
}
function calculateInstrumentsScore(
  instruments: InvestorProfileFormInputs["financialKnowledgeAndExperience"]["instruments"]
): number {
  const maxScorePerInstrument = 3;
  const totalInstruments = Object.keys(instruments).length;
  const maxTotalScore = totalInstruments * maxScorePerInstrument; // Max possible score is 33 points for 11 instruments
  let totalScore = 0;

  for (const instrumentKey of Object.keys(instruments)) {
    const instrument = instruments[instrumentKey as keyof typeof instruments];

    // 1. Connaissance (Knowledge)
    let instrumentScore = 0;
    const holdingAndRisk =
      instrument?.knowledgeActivityInFinancialProductsInstruments || [];
    const knowledge = instrument?.knowledge || "none";

    if (knowledge === "basic") {
      instrumentScore += 0.5; // Connaissance basique: 0.5 point
    } else if (knowledge === "good") {
      instrumentScore += 1; // Bonne connaissance: 1 point
    }

    // 2. Risque de perte en capital (Risk of capital loss)
    if (holdingAndRisk.includes("RISK_CAPITAL_LOSS")) {
      instrumentScore += 0.5;
    }

    // 3. Détention (Holdings)
    if (holdingAndRisk.includes("HOLDINGS")) {
      instrumentScore += 0.5;
    }

    // 4. Nombre de transactions sur les 12 derniers mois (Transactions in the last 12 months)
    switch (instrument?.transactionsNumberLast12Months) {
      case "LESS_3":
        instrumentScore += 0.1;
        break;
      case "BETWEEN_3_12":
        instrumentScore += 0.3;
        break;
      case "MORE_12":
        instrumentScore += 0.5;
        break;
      default:
        break;
    }

    // 5. Montant des investissements sur les 12 derniers mois (Investment amount in the last 12 months)
    switch (instrument?.investmentsAmountMadeLast12Months) {
      case "LESS_1000":
        instrumentScore += 0.1;
        break;
      case "BETWEEN_1000_10000":
        instrumentScore += 0.3;
        break;
      case "MORE_10000":
        instrumentScore += 0.5;
        break;
      default:
        break;
    }

    totalScore += instrumentScore;
  }

  // Normalize the total score to 20 points scale
  const normalizedScore = (totalScore / maxTotalScore) * 20;

  return normalizedScore;
}
function calculateFinancialKnowledgeExperienceLastPartScore(
  financialKnowledge: InvestorProfileFormInputs["financialKnowledgeAndExperience"]
) {
  let score = 0;
  if (!financialKnowledge) return score;

  // 1. Investor Rate Scoring
  switch (financialKnowledge.investissorRate) {
    case "NOVICE":
      score += 1;
      break;
    case "FAIRLY_EXPERIENCED":
      score += 2;
      break;
    case "EXPERIENCED":
      score += 3;
      break;
    case "HIGHLY_EXPERIENCED":
      score += 4;
      break;
    default:
      break;
  }

  // 2. Financial Investment Time Scoring
  switch (financialKnowledge.financialInvestmentsTime) {
    case "0_2_YEARS":
      score += 1;
      break;
    case "3_5_YEARS":
      score += 2;
      break;
    case "6_10_YEARS":
      score += 3;
      break;
    case "11_20_YEARS":
    case "MORE_20_YEARS":
      score += 4;
      break;
    default:
      break;
  }

  // 3. Experience of Gains and Losses Scoring
  switch (financialKnowledge.bestDescribes) {
    case "BOTH_GAINS_&_LOSSES":
      score += 2;
      break;
    case "MOSTLY_GAINS":
      score += 3;
      break;
    case "MOSTLY_LOSSES":
      score += 1;
      break;
    default:
      break;
  }

  // 4. Reaction to Drop in Value (0-3 points)
  switch (financialKnowledge.observedDecreaseValue) {
    case "WAIT_DETAILS":
      score += 1;
      break;
    case "NOT_CHANGE":
      score += 2;
      break;
    case "REINVESTED_SAME":
      score += 3;
      break;
    default:
      break;
  }

  // 5. Knowledge of Management Modes (0-1.5 points)
  if (financialKnowledge.knownManagementModes) {
    const knownModesCount = Object.values(
      financialKnowledge.knownManagementModes
    ).filter((value) => !!value && value !== "NONE").length;
    score += Math.min(knownModesCount * 0.5, 1.5); // Max 1.5 points
  }

  // 6. Chosen Management Modes (0-1.5 points)
  if (financialKnowledge.choiceManagementModes) {
    const chosenModesCount = Object.values(
      financialKnowledge.choiceManagementModes
    ).filter((value) => !!value && value !== "NONE").length;
    score += Math.min(chosenModesCount * 0.5, 1.5); // Max 1.5 points
  }

  // 7. Information Sources for Investments (0-5 points)
  if (financialKnowledge.informationSourcesForInvestments) {
    let infoSourcesScore = 0;
    const sources = financialKnowledge.informationSourcesForInvestments;
    // Mapping based on key-value pairs
    for (const source of Object.values(sources)) {
      switch (source) {
        case "SURROUNDINGS":
          infoSourcesScore += 0.25;
          break;
        case "GENERAL_PRESS":
          infoSourcesScore += 0.5;
          break;
        case "ECONOMIC_&_FINANCIAL_PRESS":
          infoSourcesScore += 1;
          break;
        case "TELEVISION_RADIO":
          infoSourcesScore += 0.5;
          break;
        case "SPECIALIZED_WEBSITES":
          infoSourcesScore += 1;
          break;
        case "PROFESSIONAL":
          infoSourcesScore += 1;
          break;
        case "SOCIAL_NETWORKS":
          infoSourcesScore += 0.25;
          break;
        default:
          break;
      }
    }

    // Cap the score at a maximum of 5 points
    score += Math.min(infoSourcesScore, 5);
  }

  return score;
}

function calculateFinancialKnowledgeExperienceScore(
  financialKnowledge: InvestorProfileFormInputs["financialKnowledgeAndExperience"]
): number {
  let score = 0;

  if (financialKnowledge?.instruments) {
    score += calculateInstrumentsScore(financialKnowledge.instruments);
  }

  score +=
    calculateFinancialKnowledgeExperienceLastPartScore(financialKnowledge);

  return score;
}

export const normalizeToMaxFive = (value: number) => Math.ceil((value * 5) / 7);

export function newProfileInvestForm(
  investorProfileForm: InvestorProfileFormInputs
) {
  const donutData = calcInvestorProfileStats();

  let knowledgeAndExperienceResult = donutData[0];
  let riskProfileResult = donutData[1];
  let sustainableInvestmentResult = donutData[2];
  const isFirstVersion = investorProfileForm?.version === "1";
  const hasManualSri = investorProfileForm?.manualSri !== undefined;
  const defaultSri =
    investorProfileForm?.manualSri ?? investorProfileForm?.final_sri;

  if (!isFirstVersion) {
    if (investorProfileForm) {
      // Calculate knowledge and experience
      const knowledgeAndExperienceScore =
        calculateFinancialKnowledgeExperienceScore(
          investorProfileForm?.financialKnowledgeAndExperience
        );

      if (knowledgeAndExperienceScore < 9) {
        knowledgeAndExperienceResult = 0;
      } else if (knowledgeAndExperienceScore < 17) {
        knowledgeAndExperienceResult = 1;
      } else {
        knowledgeAndExperienceResult = 2;
      }

      // Calculate sustainable investment
      const sustainableInvestmentScore = calculateSustainableInvestmentScore(
        investorProfileForm.sustainableInvestment
      );

      if (sustainableInvestmentScore < 1.5) {
        sustainableInvestmentResult = 0;
      } else if (sustainableInvestmentScore < 3) {
        sustainableInvestmentResult = 1;
      } else if (sustainableInvestmentScore < 5) {
        sustainableInvestmentResult = 3;
      } else {
        sustainableInvestmentResult = 4;
      }
    }
    const finalSri = investorProfileForm?.final_sri;

    const hasSri = !Number.isNaN(finalSri);

    if (hasSri) {
      riskProfileResult = finalSri;
      if (finalSri === 7) {
        riskProfileResult = 6;
      }
    }
    riskProfileResult = normalizeToMaxFive(riskProfileResult);
  }

  return {
    knowledgeAndExperienceResult,
    riskProfileResult,
    sustainableInvestmentResult,
    isFirstVersion,
    hasManualSri,
    defaultSri,
  };
}
