import { routes } from "routing";
import { RouteType } from "core";
import {
  HomeScreen,
  GenericPage,
  AddWallet,
  ProfileScreen,
  SCPI,
  RetoolScreen,
} from "screens/private";
import { RoutesType } from "./routes";
import { pick } from "core/helpers/object";
import { LoginScreen } from "screens/public/auth";
import { Dashboard } from "layouts/dashboard/dashboard";
import { RealEstate } from "screens/private/realEstate/realEstate";
import { AssetViewById } from "screens/private/assetById/assetById";
import { HeritageObjectives } from "screens/private/other/heritageObjectives";

const SubMenuList = (routeList: RoutesType[]): RouteType[] =>
  routeList.map((key: RoutesType) => ({
    ...pick(routes[key], [
      "path",
      "menuName",
      "menuIcon",
      "menuClassName",
      "menuVisibility",
      "menuDisabled",
      "label",
    ]),
    element: (
      <Dashboard>
        <GenericPage />
      </Dashboard>
    ),
  }));

export const routeList: Array<RouteType> = [
  //    Public routes
  {
    path: routes.home.path,
    menuName: "PATRIMOINE",
    element: (
      <Dashboard>
        <HomeScreen />
      </Dashboard>
    ),
    menuVisibility: "always",
    menuIcon: "home",
    label: routes.home.label,
  },
  {
    label: "sidebar.financial_wealth",
    children: SubMenuList([
      "bankAccount",
      "stocks",
      "crypto",
      "economies",
      "retirement",
      "lifeInsurance",
      "crowdfunding",
    ]),
  },
  {
    label: "sidebar.realEstate_wealth",
    children: SubMenuList(["realEstate", "privateCivilRealEstate"]),
  },
  {
    label: "sidebar.loans",
    children: SubMenuList(["loan"]),
  },
  {
    label: "sidebar.exotic.title",
    path: routes.exotic.path,
    element: (
      <Dashboard>
        <GenericPage />
      </Dashboard>
    ),
  },
  {
    path: routes.addWallet.path,
    element: (
      <Dashboard className="flex flex-col">
        <AddWallet />
      </Dashboard>
    ),
    menuVisibility: "never",
  },
  {
    path: routes.heritageObjectives.path,
    element: (
      <Dashboard>
        <HeritageObjectives />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "autres",
    label: "sidebar.wealth_objectives",
  },
  {
    path: routes.login.path,
    element: <LoginScreen pageType="login" />,
    menuVisibility: "never",
    auth: false,
  },
  {
    path: routes.register.path,
    element: <LoginScreen pageType="register" />,
    menuVisibility: "never",
    auth: false,
  },
  {
    path: routes.forgotPassword.path,
    element: <LoginScreen pageType="forgot-password" />,
    menuVisibility: "never",
    auth: false,
  },
  {
    path: routes.profile.path,
    element: (
      <Dashboard>
        <ProfileScreen />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "star",
    label: "sidebar.profile",
  },
  {
    path: routes.singleRealEstate.path,
    element: (
      <Dashboard>
        <RealEstate />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "star",
    label: "sidebar.singleRealEstate",
  },
  {
    path: routes.singleSCPI.path,
    element: (
      <Dashboard>
        <SCPI />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "star",
    label: "sidebar.singleSCPI",
  },
  {
    path: routes.retool.path,
    element: (
      <Dashboard>
        <RetoolScreen />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "star",
    label: "sidebar.retool",
  },
  {
    path: routes.assetDetails.path,
    element: (
      <Dashboard>
        <AssetViewById />
      </Dashboard>
    ),
    menuVisibility: "never",
    menuIcon: "star",
    label: "sidebar.retool",
  },
];
export const authFallBack: string = routes.login.path;
