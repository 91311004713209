import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Typo, Widget } from "components";
import type { NormalizeTranslationKeys } from "i18n";
import { client } from "core/queries/makeGQLQuery";
import {
  newProfileInvestForm,
  normalizeToMaxFive,
} from "screens/private/other/components/investorProfileForm/utils";
import type { UserInterface } from "@types";
import { config } from "config";
import { clsx } from "core";

const InvestorProfileMapping = {
  experience: {
    0: "nonDefined",
    1: "beginner",
    2: "informed",
    3: "experienced",
  },
  risk: {
    0: "nonDefined",
    1: "secure",
    2: "defensive",
    3: "balanced",
    4: "experienced",
    5: "offensive",
  },
  sensitivity: {
    0: "nonDefined",
    1: "neutral",
    2: "moderate",
    3: "significant",
    4: "strong",
  },
  environmentalActivities: {
    0: "nonDefined",
    1: "sensible",
    2: "neutral",
    3: "unSensile",
  },
  environmentalSocialGoal: {
    0: "nonDefined",
    1: "sensible",
    2: "neutral",
    3: "unSensile",
  },
  negativeImpacts: {
    1: "yes",
    2: "no",
  },
};

interface InvestorProfileProps {
  user: UserInterface | null;
}
export function InvestorProfile({ user }: InvestorProfileProps) {
  const { t } = useTranslation();

  const investorProfileForm = useQuery("investorProfileForm", () =>
    client
      .request(
        gql`
          query GetInvestorProfileForm {
            getUserInvestorProfileForm
          }
        `
      )
      .then((res) => res.getUserInvestorProfileForm)
  );

  const {
    knowledgeAndExperienceResult,
    riskProfileResult,
    hasManualSri,
    defaultSri,
    sustainableInvestmentResult,
  } = newProfileInvestForm(investorProfileForm.data);

  return (
    <div className="mt-8">
      <div className="mb-3 flex items-center justify-between">
        <Typo
          type="wlc_inter_xxl"
          className="text-base tracking-tighter text-primary"
        >
          title.investorProfile
        </Typo>
        <a
          href={`${config.proUrl}/forms/external-profile-invest/${user?.id}`}
          className="ml-auto flex h-9 w-[83px] cursor-pointer items-end justify-end gap-2 rounded-[64px] border border-stone-200 bg-white py-1.5 pl-4 pr-3 hover:opacity-50"
        >
          <span className="text-center text-sm font-medium leading-normal text-slate-900">
            {t("actions.update")}
          </span>
        </a>
      </div>
      <div className="flex flex-col gap-4">
        <InvestorProfileLine
          title="page.profil.investor.knowlegdeExperience"
          data={InvestorProfileMapping.experience}
          value={knowledgeAndExperienceResult}
        />

        <InvestorProfileLine
          title="page.profil.investor.riskProfile"
          data={InvestorProfileMapping.risk}
          value={
            hasManualSri
              ? normalizeToMaxFive(
                  investorProfileForm.data?.manualSri as number
                )
              : riskProfileResult
          }
        />

        <InvestorProfileLine
          title="page.profil.investor.financialSensitivity"
          data={InvestorProfileMapping.sensitivity}
          value={sustainableInvestmentResult}
        />

        <SRI defaultSri={defaultSri} />
      </div>
    </div>
  );
}

interface SRIProps {
  defaultSri: number | null;
}
function SRI({ defaultSri }: SRIProps) {
  return (
    <Widget label="page.profil.investor.sri">
      <div className="mx-4">
        <div className="m-auto flex grid-cols-12 gap-2 py-2">
          {Array.from({ length: 7 }).map((_, index) => (
            <div
              key={`sri-${index + 1}`}
              className={clsx(
                "rounded-full py-0 text-sm",
                defaultSri === index + 1
                  ? "bg-blue-800 text-white"
                  : "bg-[#4761C84D]",
                defaultSri ? "px-5" : "px-4"
              )}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    </Widget>
  );
}

interface InvestorProfileLine {
  data: { [key: number]: string };
  title: NormalizeTranslationKeys;
  value: number;
}
function InvestorProfileLine({ title, data, value }: InvestorProfileLine) {
  const { t } = useTranslation();

  const dataLength = Object.keys(data).length;
  const percentage = data[value] ? (value * 100) / (dataLength - 1) : 0;

  return (
    <Widget label={title}>
      <div className="mx-4">
        <div className="flex-row justify-between">
          <p className="text-content-tertiary font-sans text-sm font-medium text-zinc-400">
            {data[value]
              ? t(
                  `page.profil.investor.${data[value]}` as NormalizeTranslationKeys
                )
              : t("page.profil.investor.nonDefined")}
          </p>
        </div>
        <div className="bg-background-tertiary relative my-1 h-4 rounded-full">
          <progress
            max="100"
            value={percentage}
            className="h-6 w-full overflow-hidden rounded-xl"
            id="investorProfileProgress"
          />
        </div>
      </div>
    </Widget>
  );
}
