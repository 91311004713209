import { UserInterface } from "@types";
import { Container, Typo } from "components";
import { InvestorProfile } from "../InvestorProfile";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/RadixTabs";
import { useTranslation } from "react-i18next";
import { ProfileInformationsGeneral } from "./ProfileInformationsGeneral";
import { ProfileInformationsDetails } from "./ProfileInformationsDetails";

export function ProfileInformations({ user }: { user: UserInterface | null }) {
  const { t } = useTranslation();

  return (
    <>
      <Container className="rounded-xl p-4">
        <Typo
          type="wlc_inter_l"
          children="forms.title.generalInformations"
          className="mb-8 text-lg tracking-tight text-primary"
        />
        <Tabs defaultValue="general">
          <TabsList>
            <TabsTrigger value="general">
              {t("forms.customers.details.tabs.general")}
            </TabsTrigger>
            <TabsTrigger value="infos">
              {t("forms.customers.details.tabs.details")}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="general">
            <ProfileInformationsGeneral
              defaultValues={user?.informations?.general}
            />
          </TabsContent>
          <TabsContent value="infos">
            <ProfileInformationsDetails
              defaultValues={user?.informations?.details}
            />
          </TabsContent>
        </Tabs>
      </Container>
      <InvestorProfile user={user} />
    </>
  );
}
